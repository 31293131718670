<template>
  <div id="earn" class="token-sale p-tb c-l black-bg white-sec">
    <div class="container mt-6">
      <div id="about" class="about-section style-2 p-tb">
        <div class="row align-items-center">
          <div class="col-md-8 about-left align-self-baseline">
            <div class="sec-title"><h1>$Earn with Elros</h1></div>
            <h5 class="accent-color">By participating in our tasks you can earn rewards. <br>Let's collaborate to mutually benefit each other!</h5>
            <h5 class="lead">Prize pool for July: 23.5M $ELRS - Completed</h5>
            <h5 class="lead">Prize pool for August: 5M $ELRS - Completed</h5>
            <h5 class="lead">Prize pool for September: 12M $ELRS - Completed</h5>
            <h5 class="lead">Prize pool for October: 5M $ELRS - Completed</h5>
            <h5 class="lead">Prize pool for November: 4.5M $ELRS - Target Not Reached </h5>
            <h5 class="lead">Prize pool for December: 2.4M $ELRS - Completed </h5>
            <h5 class="lead">Prize pool for January: 2.4M $ELRS - Target Not Reached </h5>
            <h5 class="lead">Prize pool for February: 2.4M $ELRS - Target Not Reached </h5>
            <a class="btn el-btn mt-4" href="https://forms.gle/YWmG59rKKMGcrTTt7" target="_blank">Join the army</a>
          </div>
          <div class="col-md-4 about-left leaderboard-container mt-4">
            <el-leaderboard :users="getLeaderboards" />
          </div>
        </div>
        <!-- FAQ Section start-->
        <div id="faq" class="faq-section p-t">
          <div class="container">
            <div class="sec-title text-center"><h3>Frequently Asked Questions</h3></div>
            <div class="row">
              <div class="col-sm-12">
                <div class="accordion md-accordion">
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        What do we require?
                      </h5>
                    </div>
                    <div class="card-body">
                      We are seeking to recruit individuals who can assist us with various tasks, including engaging with different platforms like Twitter, CMC, Telegram groups, Dextools, and any other necessary actions that can support our project or other initiatives.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        What do we provide?
                      </h5>
                    </div>
                    <div class="card-body">
                      Every month, we will allocate a budget that will be distributed among those who actively participate in these tasks. The budget and specific tasks may vary from month to month, but they will be shared among individuals who hold the "Army" role on our website, www.elroscrypto.com.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        How can you obtain the "Army" role?
                      </h5>
                    </div>
                    <div class="card-body">
                      We will open the recruitment process to select 20 members for our army. The number of slots for the army will initially be limited, but it may expand in the future. We will establish a gamification system that awards points for each interaction you make.

                      Our website will feature two leaderboards: one for each month, which resets at the beginning of each month, and an overall leaderboard that tracks your performance over time. Depending on your position on the leaderboard, you will receive certain advantages. Detailed information will be provided as we progress.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        What do I need to do to earn the Army status?
                      </h5>
                    </div>
                    <div class="card-body">
                      Currently, you must be a holder for at least 2.5M tokens and complete this form. Once you've done that, we will contact you and add you to a dedicated channel where you will receive the assigned tasks.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        When will the prizes be distributed?
                      </h5>
                    </div>
                    <div class="card-body">
                      On the first Monday of the following month, we will distribute the prizes based on your performance. We will calculate your points from the total points earned and determine the percentage you contributed. That percentage will be sent to your wallet address in the subsequent month.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FAQ Section end-->
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { Leaderboard } from '@/components/shared/leaderboard';
  import logo from '@/assets/png/black-gold.png';
  import sp from '@/assets/png/sp.png';
  import hf from '@/assets/png/hf.png';

  export default Vue.extend({
    name: 'elros-earn',
    components: {
      'el-leaderboard': Leaderboard,
    },
    data: () => ({
      logo,
      ca: '0x91c6d8B00C1e250963849DffD7B1CBb3342683aF',
      sp,
      hf,
      serviceProviders: [
        {
          username: 'StepanZe1',
          logo: sp,
          role: 'Designer',
          quote: 'Don\'t get too attached to ideas. They can change, and there are always other options.',
          description: 'Experienced graphic designer with versatile creative skills. Responsible team player adept at modern graphic design. Continuously improving and exploring new ideas for creativity and skill development. Expertise includes logo design, thematic posters, 2D/3D video teasers and posters, stickers, and project visualization.',
          services: [],
        },
      ],
    }),
    computed: {
      ...mapGetters({
        getLeaderboards: 'common/getLeaderboards',
      }),
      getServiceProvider() {
        return this.serviceProviders[0];
      },
    },
    created() {
      this.fetchLeaderboards('current_score');
    },
    methods: {
      ...mapActions({
        fetchLeaderboards: 'common/fetchLeaderboards',
      }),

      onShowTotal() {
        this.fetchLeaderboards('total_score');
      },
    },
  });
</script>
<style>
.lead {
  color: #ffffff !important;
}

.leaderboard-container {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  color: #bbbbbb;
  cursor: pointer;
  display: table;
  margin-bottom: 30px;
  padding: 30px;
  transition: all 500ms;
}

.leaderboard-container h2 {
  border-bottom: 3px solid #ffffff;
  color: #ffffff;
}

.leaderboard-container li {
  background-color: transparent !important;
  color: #ffffff;
  font-size: 24px;
}

.faq-section {
  background-color: #000000;
}

#earn .card,
#earn .card-header {
  background-color: #000000 !important;
  color: #ffffff !important;
}

#earn .card-header h5 {
  color: #ffffff !important;
}

.align-self-baseline {
  align-self: baseline;
}

</style>
